<template>
  <div class="my-card">
    <img src="https://img.zcool.cn/community/0161c25d75c6afa801211d530380f7.png" alt="">
    <div class="des">点击图片长按保存</div>
    <van-button to="/setCard" block type="info">管理名片信息</van-button>
  </div>
</template>

<script>
import mine from '@/api/mine';

export default {
  name: 'myCard',
  async mounted() {
    let {code, data} = await mine.getPosters();
    console.log(code, data);
  }
};
</script>

<style lang="less" scoped>
.my-card {
  padding: 24px 16px;

  img {
    width: 343px;
    height: 485px;
    border-radius: 4px;
    border: 1px solid #EBEEF5;
  }

  .des {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: @gary-wxb-gray-2;
    line-height: 17px;
    margin-top: 23px;
    margin-bottom: 40px;
  }
}
</style>
